import { MainLayout } from "@/layout/MainLayout";

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        <p className="text-lg mb-8 text-muted-foreground">
          Last updated: {new Date().toLocaleDateString()}
        </p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Privacy Commitment</h2>
          <p className="text-lg mb-4">
            At Temp-mail.lol, privacy isn't just a feature - it's our core purpose. We provide disposable email addresses to help you:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Keep your real email address private</li>
            <li>Avoid spam and unwanted marketing</li>
            <li>Protect against phishing attempts</li>
            <li>Prevent your email from being sold to third parties</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">No Personal Data Collection</h2>
          <p className="text-lg mb-4">
            We're serious about privacy:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>No account creation or registration required</li>
            <li>No personal information collected</li>
            <li>No IP address logging</li>
            <li>No user tracking or analytics</li>
            <li>No cookies except those essential for basic functionality</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Email Handling</h2>
          <p className="text-lg mb-4">
            How we handle temporary emails:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>All emails are automatically deleted after 7 days - no exceptions</li>
            <li>No backups are kept</li>
            <li>Emails are stored temporarily only to provide the service</li>
            <li>No email content is analyzed or processed for any other purpose</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Service Access</h2>
          <p className="text-lg mb-4">
            Access options:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Direct web access - no login required</li>
            <li>Android app (coming soon) - same no-account policy</li>
          </ul>
          <p className="text-lg mt-4">
            Our upcoming Android app follows the same strict privacy principles - no tracking, no accounts, no personal data collection.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Your Privacy Rights</h2>
          <p className="text-lg mb-4">
            Since we don't collect personal data:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>No personal data to request or export</li>
            <li>No profiles or accounts to delete</li>
            <li>Complete privacy by design</li>
            <li>Temporary emails automatically purged after 7 days</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Security Measures</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>HTTPS encryption for all web traffic</li>
            <li>Automatic email deletion after 7 days</li>
            <li>No permanent data storage</li>
            <li>Regular security updates</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Policy Updates</h2>
          <p className="text-lg mb-4">
            If we update this policy:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Changes will be posted on our website</li>
            <li>No email notifications (since we don't collect email addresses)</li>
            <li>Policy date will be updated</li>
            <li>Previous versions will be available on request</li>
          </ul>
        </section>
      </div>
    </MainLayout>
  );
};

export default PrivacyPolicy; 