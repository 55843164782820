import { Card } from "@/components/ui/card";
import { useEmail } from "@/contexts/EmailContext";
import { useState, useEffect } from "react";
import { EmailList } from "@/components/email/EmailList";
import { EmailDetailsDialog } from "@/components/email/EmailDetailsDialog";
import { QRCodeDialog } from "@/components/email/QRCodeDialog";
import { EmailGenerator } from "@/components/email/EmailGenerator";
import { EmailRefreshControl } from "@/components/email/EmailRefreshControl";
import { useIsMobile } from "@/hooks/use-mobile";
import { Button } from "@/components/ui/button";
import { Layout, HelpCircle, Coffee, ArrowRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Logo } from "@/components/layout/Logo";
import type { Email } from "@/contexts/EmailContext";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { MainLayout } from "@/layout/MainLayout";
import { Link } from "react-router-dom";

const Home = () => {
  const { emails, isLoading, generatedEmail } = useEmail();
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);
  const [mounted, setMounted] = useState(false);
  const [showHowToUse, setShowHowToUse] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleEmailClick = (email: Email) => {
    setSelectedEmail(email);
  };

  if (!mounted) {
    return null;
  }

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  return (
    <MainLayout>
      <motion.div 
        className={`flex flex-col items-center justify-center space-y-4 sm:space-y-6 md:space-y-8 animate-fade-in 
          ${isMobile ? 'px-2' : 'px-4'} max-w-7xl mx-auto`}
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className={`text-center space-y-4 ${isMobile ? 'space-y-4' : 'space-y-6'} w-full max-w-4xl`}
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Logo />
          
          <div className="space-y-2">
            <p className="text-muted-foreground text-sm sm:text-base md:text-lg">
              Get an instant disposable email address
            </p>
            <button
              onClick={() => setShowHowToUse(true)}
              className="text-primary hover:text-primary/80 text-sm sm:text-base flex items-center justify-center mx-auto gap-1"
            >
              How to Use <HelpCircle className="h-4 w-4" />
            </button>
          </div>

          <EmailGenerator onOpenQRModal={() => setIsQRModalOpen(true)} />

          <Card className="p-3 sm:p-4 md:p-6 glass">
            <EmailRefreshControl />
          </Card>

          <Card className="p-3 sm:p-4 md:p-6 glass overflow-x-auto">
            <EmailList 
              emails={emails}
              isLoading={isLoading}
              onViewEmail={handleEmailClick}
            />
          </Card>

          {/* Why Use Temp Mail Section */}
          <Card className="p-4 sm:p-5 md:p-6 glass overflow-hidden">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center">Why Use Temp Mail?</h2>
            </motion.div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              {[
                {
                  title: "Protect Your Privacy",
                  description: "Keep your primary email address private and avoid spam by using temporary email addresses for online registrations and verifications.",
                  icon: "🔒"
                },
                {
                  title: "Prevent Spam",
                  description: "Use disposable emails for one-time signups and avoid getting bombarded with marketing emails and newsletters.",
                  icon: "📧"
                },
                {
                  title: "Safe Online Shopping",
                  description: "Shop online without worrying about your email being added to marketing lists or exposed in data breaches.",
                  icon: "🛍️"
                },
                {
                  title: "Test Services Safely",
                  description: "Try out new services and apps without committing your personal email address.",
                  icon: "🧪"
                }
              ].map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className="relative p-3 sm:p-4 rounded-lg bg-background/50 backdrop-blur-sm border border-border/50"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
                >
                  <motion.div
                    className="absolute -top-3 sm:-top-4 -left-3 sm:-left-4 w-7 h-7 sm:w-8 sm:h-8 rounded-full bg-primary/20 flex items-center justify-center text-lg sm:text-xl"
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3, delay: index * 0.1 + 0.2 }}
                  >
                    {feature.icon}
                  </motion.div>
                  <div className="space-y-2 pl-3 sm:pl-4">
                    <h3 className="font-semibold text-base sm:text-lg">{feature.title}</h3>
                    <p className="text-xs sm:text-sm text-muted-foreground">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </Card>

          {/* How We're Different Section */}
          <Card className="p-4 sm:p-5 md:p-6 glass overflow-hidden">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center">How We're Different</h2>
            </motion.div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              {[
                {
                  title: "Reusable Addresses",
                  description: "Unlike other services that offer one-time use addresses, temp-mail.lol provides genuinely reusable addresses that remain accessible across sessions.",
                  icon: "🔄",
                  highlight: "Unique Feature"
                },
                {
                  title: "Full Attachment Support",
                  description: "While 63% of temporary email services block all attachments, we support receiving attachments with robust security scanning up to 25MB.",
                  icon: "📎",
                  highlight: "Industry Leading"
                },
                {
                  title: "7-Day Persistence",
                  description: "Most services retain messages for just 60 minutes to 24 hours. We offer a full week of address persistence—an industry-leading timeframe.",
                  icon: "⏱️",
                  highlight: "Longest Retention"
                },
                {
                  title: "No Registration Required",
                  description: "Maintain complete anonymity while using our service. No account creation, no personal information required.",
                  icon: "👤",
                  highlight: "Zero Friction"
                }
              ].map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className="relative p-3 sm:p-4 rounded-lg bg-background/50 backdrop-blur-sm border border-border/50"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
                >
                  <motion.div
                    className="absolute -top-3 sm:-top-4 -left-3 sm:-left-4 w-7 h-7 sm:w-8 sm:h-8 rounded-full bg-primary/20 flex items-center justify-center text-lg sm:text-xl"
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3, delay: index * 0.1 + 0.2 }}
                  >
                    {feature.icon}
                  </motion.div>
                  <div className="space-y-2 pl-3 sm:pl-4">
                    <div className="flex items-center gap-2 flex-wrap">
                      <h3 className="font-semibold text-base sm:text-lg">{feature.title}</h3>
                      <span className="text-xs px-2 py-0.5 rounded-full bg-primary/10 text-primary">
                        {feature.highlight}
                      </span>
                    </div>
                    <p className="text-xs sm:text-sm text-muted-foreground">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </Card>

          {/* Blog Section */}
          <Card className="p-4 sm:p-5 md:p-6 glass">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2 sm:gap-4 mb-4">
              <h2 className="text-xl sm:text-2xl font-bold">Latest Blog Posts</h2>
              <Link to="/blog" className="text-primary hover:text-primary/80 flex items-center gap-1 text-sm sm:text-base">
                View All <ArrowRight className="h-4 w-4" />
              </Link>
            </div>
            <div className="space-y-4">
              <div className="space-y-2">
                <h3 className="font-semibold text-base sm:text-lg">Why You Should Use temp-mail.lol for Disposable Email Needs</h3>
                <p className="text-xs sm:text-sm text-muted-foreground">
                  Discover why temp-mail.lol is your best choice for disposable email needs with unique features like email reusability, attachment support, and enhanced privacy.
                </p>
                <Link to="/blog/why-use-temp-mail-lol" className="text-primary hover:text-primary/80 text-xs sm:text-sm">
                  Read More →
                </Link>
              </div>
              <div className="space-y-2">
                <h3 className="font-semibold text-base sm:text-lg">How to Protect Your Privacy Online with Temporary Emails</h3>
                <p className="text-xs sm:text-sm text-muted-foreground">
                  Learn essential strategies for safeguarding your digital privacy using temporary email services alongside other proven privacy protection methods.
                </p>
                <Link to="/blog/how-to-protect-privacy-with-temp-email" className="text-primary hover:text-primary/80 text-xs sm:text-sm">
                  Read More →
                </Link>
              </div>
            </div>
          </Card>
        </motion.div>

        <Dialog open={showHowToUse} onOpenChange={setShowHowToUse}>
          <DialogContent className="max-w-[90vw] sm:max-w-md">
            <DialogHeader>
              <DialogTitle>How to Use OnlyMail</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <div className="space-y-2">
                <h3 className="font-medium text-sm sm:text-base">1. Get Your Email Address</h3>
                <p className="text-xs sm:text-sm text-muted-foreground">Generate a random email address or customize your own username. Click "New" to get a different address.</p>
              </div>
              <div className="space-y-2">
                <h3 className="font-medium text-sm sm:text-base">2. Use Your Temporary Email</h3>
                <p className="text-xs sm:text-sm text-muted-foreground">Use this email address wherever you need. All incoming emails will appear automatically in the list below.</p>
              </div>
              <div className="space-y-2">
                <h3 className="font-medium text-sm sm:text-base">3. Access Your Messages</h3>
                <p className="text-xs sm:text-sm text-muted-foreground">Click on any email in the list to read its contents. Messages are automatically refreshed, but you can also manually refresh.</p>
              </div>
              <div className="space-y-2">
                <h3 className="font-medium text-sm sm:text-base">4. Share Your Email</h3>
                <p className="text-xs sm:text-sm text-muted-foreground">Use the QR code feature to easily share your temporary email address with others.</p>
              </div>
              <div className="space-y-2 pt-4 border-t">
                <h3 className="font-medium text-primary text-sm sm:text-base">❤️ Support temp-mail.lol</h3>
                <p className="text-xs sm:text-sm text-muted-foreground">
                  We provide this service free of charge, but running servers and maintaining the service requires resources. 
                  If you find temp-mail.lol useful, please consider supporting us:
                </p>
                <div className="flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-4 pt-2">
                  <a 
                    href="https://buymeacoffee.com/arctronic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-muted-foreground hover:text-primary transition-colors text-sm"
                  >
                    <Coffee className="h-4 w-4 sm:h-5 sm:w-5" />
                    <span>Buy me a coffee</span>
                  </a>
                  <a
                    href="https://github.com/temp-mail-lol"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-md bg-secondary text-secondary-foreground hover:bg-secondary/90 transition-colors text-sm"
                  >
                    ⭐ Star on GitHub
                  </a>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <QRCodeDialog
          isOpen={isQRModalOpen}
          onOpenChange={setIsQRModalOpen}
          email={generatedEmail}
        />

        <EmailDetailsDialog
          email={selectedEmail}
          onOpenChange={() => setSelectedEmail(null)}
        />
      </motion.div>
    </MainLayout>
  );
}

export default Home;
