import React from "react";
import { Footer } from "@/components/layout/Footer";
import { AdUnit } from "@/components/ads/AdUnit";
import { Helmet } from "react-helmet";
import Navbar from "@/components/layout/Navbar";

interface MainLayoutProps {
  children: React.ReactNode;
}

export function MainLayout({ children }: MainLayoutProps) {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebApplication",
              "name": "OnlyMail",
              "description": "Free temporary email service for protecting your privacy and avoiding spam",
              "url": "https://only-mail.com",
              "applicationCategory": "Email Service",
              "operatingSystem": "All",
              "offers": {
                "@type": "Offer",
                "price": "0"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="min-h-screen flex flex-col bg-background text-foreground">
        <Navbar />
        <div className="w-full max-w-[728px] mx-auto">
        <AdUnit slot="header" />
      </div>
        <div className="relative flex-grow">
          {/* Left ad - fixed to left edge */}
          <aside className="hidden xl:block fixed left-4 top-24 min-w-[300px] max-w-[480px]">
            <AdUnit slot="sidebar" />
          </aside>

          {/* Right ad - fixed to right edge */}
          <aside className="hidden xl:block fixed right-4 top-24 min-w-[300px] max-w-[480px]">
            <AdUnit slot="sidebar" />
          </aside>

          {/* Main content */}
          <main className="container mx-auto px-4 mb-[90px] mt-16">
            {children}
          </main>
        </div>

        {/* Footer ad */}
        <div className="container mx-auto px-4 mb-4">
          <AdUnit slot="footer"/>
        </div>
        
        <Footer className="fixed bottom-0 w-full bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 z-50 border-t border-border/50" />
      </div>
    </>
  );
}
