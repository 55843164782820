import { MainLayout } from "@/layout/MainLayout";
import { useState } from "react";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";

interface FAQItem {
  question: string;
  answer: string | React.ReactNode;
}

interface FAQSectionProps {
  title: string;
  items: FAQItem[];
}

const FAQSection = ({ title, items }: FAQSectionProps) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">{title}</h2>
      <div className="space-y-4">
        {items.map((item, index) => (
          <div
            key={index}
            className="border border-border/50 rounded-lg overflow-hidden"
          >
            <button
              className="w-full flex items-center justify-between p-4 text-left hover:bg-primary/5 transition-colors"
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            >
              <span className="text-lg font-medium">{item.question}</span>
              <ChevronDown
                className={cn(
                  "h-5 w-5 transition-transform duration-200",
                  openIndex === index ? "transform rotate-180" : ""
                )}
              />
            </button>
            <div
              className={cn(
                "overflow-hidden transition-all duration-200 bg-muted/30",
                openIndex === index ? "max-h-96 p-4" : "max-h-0"
              )}
            >
              {typeof item.answer === "string" ? (
                <p className="text-lg">{item.answer}</p>
              ) : (
                item.answer
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const FAQ = () => {
  const generalQuestions: FAQItem[] = [
    {
      question: "What is Temp-mail.lol?",
      answer: "Temp-mail.lol is a disposable email service that helps protect your privacy by providing temporary email addresses. It's designed to keep your real email address private and prevent spam, phishing attempts, and unwanted subscriptions."
    },
    {
      question: "How does it work?",
      answer: "Just visit our website and you'll get a temporary email address instantly - no registration needed. Use this address wherever you don't want to share your real email. All emails are automatically deleted after 7 days to maintain privacy."
    },
    {
      question: "Do I need to create an account?",
      answer: "No! That's one of our key features - no account creation, no registration, and no personal information required. Just visit the site and get a temporary email address instantly."
    }
  ];

  const securityQuestions: FAQItem[] = [
    {
      question: "How secure is my data?",
      answer: "We don't store any personal data. Emails are automatically deleted after 7 days, and we don't keep backups. We don't track users, log IP addresses, or collect any identifying information."
    },
    {
      question: "Why should I use a temporary email?",
      answer: "Using a temporary email helps protect your privacy by: keeping your real email private, avoiding spam and marketing emails, reducing the risk of phishing attacks, and preventing your email from being sold to third parties."
    },
    {
      question: "Can emails be recovered after 7 days?",
      answer: "No, and that's intentional. After 7 days, emails are permanently deleted from our servers with no way to recover them. This ensures your privacy and prevents any long-term data storage."
    }
  ];

  const platformQuestions: FAQItem[] = [
    {
      question: "What platforms are supported?",
      answer: (
        <div className="space-y-2">
          <p>Access Temp-mail.lol through:</p>
          <ul className="list-disc pl-6">
            <li>Any web browser on desktop or mobile</li>
            <li>Our upcoming Android app (coming soon to Google Play Store)</li>
          </ul>
          <p className="mt-2">No installation or setup required for the web version - just visit and use!</p>
        </div>
      )
    },
    {
      question: "Are there any usage limits?",
      answer: "The service is free to use with reasonable limits to prevent abuse. While you can create multiple temporary addresses, we restrict automated mass creation to ensure service quality for everyone."
    },
    {
      question: "What if a service blocks temporary emails?",
      answer: "Some services may block known temporary email domains. This is a common practice for services that require long-term communication. In such cases, you should use your regular email address."
    }
  ];

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">Frequently Asked Questions</h1>
        <FAQSection title="General Questions" items={generalQuestions} />
        <FAQSection title="Security & Privacy" items={securityQuestions} />
        <FAQSection title="Platforms & Support" items={platformQuestions} />
      </div>
    </MainLayout>
  );
};

export default FAQ; 