import { MainLayout } from "@/layout/MainLayout";

const TermsOfService = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        <p className="text-lg mb-8 text-muted-foreground">
          Last updated: {new Date().toLocaleDateString()}
        </p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Service Overview</h2>
          <p className="text-lg mb-4">
            Temp-mail.lol is a disposable email service designed to protect your privacy by:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Providing temporary email addresses that expire automatically</li>
            <li>No account creation or personal information required</li>
            <li>No data tracking or user profiling</li>
            <li>Automatic deletion of all emails after 7 days</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Acceptable Use</h2>
          <p className="text-lg mb-4">
            You may use our service to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Protect your personal email from spam and unwanted subscriptions</li>
            <li>Avoid phishing attempts and email-based tracking</li>
            <li>Sign up for services without exposing your real email</li>
            <li>Test applications or services that require email verification</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Prohibited Activities</h2>
          <p className="text-lg mb-4">
            The service may not be used for:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Sending spam or unsolicited emails</li>
            <li>Illegal activities or harassment</li>
            <li>Attempting to bypass the 7-day deletion policy</li>
            <li>Interfering with the service's operation</li>
            <li>Creating automated scripts to mass-generate addresses</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">No Account Policy</h2>
          <p className="text-lg mb-4">
            Our service operates on a no-account basis:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>No registration or account creation required</li>
            <li>No personal information collected or stored</li>
            <li>No passwords or authentication needed</li>
            <li>No tracking or user session persistence</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Practices</h2>
          <p className="text-lg mb-4">
            Our commitment to your privacy:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>All emails are automatically deleted after 7 days</li>
            <li>No backup copies are maintained</li>
            <li>No logging of IP addresses or user activity</li>
            <li>No third-party tracking or analytics</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Service Limitations</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Emails older than 7 days cannot be recovered</li>
            <li>No guarantee of email delivery or uptime</li>
            <li>Service may be unavailable during maintenance</li>
            <li>Some email providers may block disposable email addresses</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Changes to Service</h2>
          <p className="text-lg mb-4">
            We reserve the right to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Modify or discontinue any part of the service</li>
            <li>Block abusive users or IP addresses</li>
            <li>Update these terms as needed</li>
            <li>Change the deletion period with notice</li>
          </ul>
        </section>
      </div>
    </MainLayout>
  );
};

export default TermsOfService; 