import { MainLayout } from "@/layout/MainLayout";

const About = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">About Ephemeral Postbox</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
          <p className="text-lg mb-4">
            At Ephemeral Postbox, we're dedicated to providing a secure, private, and convenient way to share sensitive information. Our mission is to empower users with the ability to communicate securely without leaving a permanent digital footprint.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
          <p className="text-lg mb-4">
            Founded in 2024, Ephemeral Postbox was created in response to growing concerns about digital privacy and data security. We recognized the need for a simple yet secure way to share sensitive information that doesn't leave a permanent record.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Key Features</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>End-to-end encryption for all messages</li>
            <li>Self-destructing messages after reading</li>
            <li>No account required for recipients</li>
            <li>Secure file sharing capabilities</li>
            <li>Cross-platform compatibility</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What Sets Us Apart</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Zero-knowledge architecture - we can't read your messages</li>
            <li>No data retention - messages are permanently deleted after reading</li>
            <li>User-friendly interface with enterprise-grade security</li>
            <li>Transparent privacy practices</li>
          </ul>
        </section>
      </div>
    </MainLayout>
  );
};

export default About; 