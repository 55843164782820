
import { Card } from "@/components/ui/card";
import { useEffect, useState } from "react";

interface AdUnitProps {
  className?: string;
  slot: "header" | "content" | "sidebar" | "footer";
}

export const AdUnit = ({ className, slot }: AdUnitProps) => {
  const [hasContent, setHasContent] = useState(false);
  
  // Check if ads are enabled via environment variable
  const showAds = import.meta.env.VITE_SHOW_ADS === "true";

  useEffect(() => {
    // Check if the page has meaningful content
    const mainContent = document.querySelector('main');
    if (mainContent) {
      // Only show ads if there's substantial content (more than just the ad container)
      const contentLength = mainContent.textContent?.trim().length || 0;
      setHasContent(contentLength > 100); // Require at least 100 characters of content
    }
  }, []);

  // Don't show ads if disabled or no content
  if (!showAds || !hasContent) return null;

  // Determine ad size based on slot
  const getAdSize = () => {
    switch (slot) {
      case "header":
        return "min-h-[90px] md:min-h-[90px]"; // Leaderboard (728x90)
      case "sidebar":
        return "min-h-[600px]"; // Skyscraper (160x600)
      case "content":
        return "min-h-[250px]"; // Medium Rectangle (300x250)
      case "footer":
        return "min-h-[90px]"; // Leaderboard (728x90)
      default:
        return "min-h-[250px]";
    }
  };

  return (
    <Card 
      className={`ad-container p-0 overflow-hidden ${getAdSize()} bg-background/50 ${className}`}
      role="complementary"
      aria-label="Advertisement Section"
    >
      <div 
        className="text-xs text-muted-foreground text-center py-1 border-b"
        aria-label="Advertisement Label"
      >
        Advertisement
      </div>
      <div 
        id={`google-ad-${slot}`} 
        className="w-full h-full flex items-center justify-center"
        style={{ minWidth: slot === "sidebar" ? "160px" : "300px" }}
        role="none presentation"
      >
        {/* Google AdSense code will be injected here */}
      </div>
    </Card>
  );
};
